.pl {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pl-texts {
    width: 65%;
}

.pl-title {
    font-size: 35px;
    font-weight: 600;
}

.pl-desc {
    margin: 20px 0;
}

.pl-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:480px) {
    .pl {
        padding: 10px;
    }

    .pl-texts {
        width: 100%;
    }

    .pl-desc {
        display: none;
    }

    
}